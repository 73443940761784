import React from "react";
import ReactPlayer from "react-player";
import { useLocation } from "react-router-dom";

const VideoPlayer = () => {
  const state = useLocation();
  const params = state.state;

  return (
    <div>
      <ReactPlayer
        url={params?.url}
        controls={true}
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default VideoPlayer;
