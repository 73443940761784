import { UploadOutlined } from "@ant-design/icons";
import { Button, Card, Input, message, Upload } from "antd";
import React from "react";
import useValidate from "../hooks/useValidate";
import { addUserNotification } from "../utils/api";

const Notification = () => {
  const [loading, setLoading] = React.useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [notification, setNotification] = React.useState({});

  const addUserNotifications = async () => {
    if (
      !notification?.title ||
      !notification?.description ||
      !notification?.image
    )
      return messageApi.error("Please fill all the fields");
    setLoading(true);
    const formData = new FormData();
    formData.append("title", notification.title);
    formData.append("description", notification.description);
    formData.append("image", notification.image);
    const [success, response] = await addUserNotification(formData);
    if (success) messageApi.success("Notification added successfully");
    setLoading(false);
  };

  useValidate();

  return (
    <>
      {contextHolder}
      <Card title="Notification" className="notification_card">
        <div className="notification">
          <Input
            placeholder="title"
            onChange={(e) => {
              setNotification({ ...notification, title: e.target.value });
            }}
          />
          <Input
            placeholder="description"
            onChange={(e) => {
              setNotification({ ...notification, description: e.target.value });
            }}
          />
          <Upload
            onChange={(e) => {
              setNotification({ ...notification, image: e.file.originFileObj });
            }}
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </div>
        <Button
          onClick={addUserNotifications}
          loading={loading}
          type="primary"
          style={{ marginTop: "20px" }}
        >
          Send
        </Button>
      </Card>
    </>
  );
};

export default Notification;
