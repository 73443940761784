import { Input, message, Modal, Table } from "antd";
import React from "react";
import Head from "../components/common/Head";
import useValidate from "../hooks/useValidate";
import { addGenre, getAllGenres, getAllTransaction } from "../utils/api";

const AllTransactions = () => {
  const [modal, setModal] = React.useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [genres, setGenres] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [newGenre, setNewGenre] = React.useState({});
  const getAllGenresData = async () => {
    setLoading(true);
    const [success, response] = await getAllTransaction();

    if (success) setGenres(response);
    setLoading(false);
  };

  useValidate();
  React.useEffect(() => {
    getAllGenresData();
  }, []);
  return (
    <>
      {contextHolder}
      <Head title="Transactions" />

      <Table
        loading={loading}
        dataSource={genres}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
          },
          {
            title: "Email",
            dataIndex: "email",
          },
          {
            title: "Phone Number",
            dataIndex: "phoneNumber",
          },
          {
            title: "Subscription Name",
            dataIndex: ["subscriptionType", "title"],
          },
          {
            title: "Amount",
            dataIndex: ["subscriptionType", "price"],
          },
        ]}
      />
    </>
  );
};

export default AllTransactions;
