import {
  PieChartOutlined,
  PictureOutlined,
  ClusterOutlined,
  VideoCameraOutlined,
  ContainerOutlined,
  UserOutlined,
  DatabaseOutlined,
  BellOutlined,
  FileDoneOutlined,
  MoneyCollectOutlined,
  ContactsOutlined,
} from "@ant-design/icons";
import logo from "../../assets/logo.png";
import { Button, Image, Layout, Menu, theme } from "antd";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useStore from "../../utils/store";
const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children, path) {
  return {
    key,
    icon,
    children,
    label,
    path,
  };
}
const items = [
  getItem("Dashboard", "1", <PieChartOutlined />, null, "/"),
  getItem("Banner", "2", <PictureOutlined />, null, "/banners"),
  getItem("Category", "3", <ClusterOutlined />, null, "/categories"),
  getItem("Genres", "4", <ContainerOutlined />, null, "/genres"),
  getItem("Movies", "5", <VideoCameraOutlined />, null, "/movies"),
  getItem("Series", "6", <DatabaseOutlined />, null, "/series"),
  getItem("Coming Soon", "12", <DatabaseOutlined />, null, "/coming-soon"),
  getItem("Users", "7", <UserOutlined />, null, "/users"),
  getItem("Notification", "8", <BellOutlined />, null, "/notification"),
  getItem("Subscription", "9", <FileDoneOutlined />, null, "/subscription"),
  getItem(
    "Transactions",
    "10",
    <MoneyCollectOutlined />,
    null,
    "/transactions"
  ),
  getItem(
    "Active Subscription",
    "11",
    <ContactsOutlined />,
    null,
    "/active-subscription"
  ),
  getItem("Poster", "13", <PictureOutlined />, null, "/poster"),
  // getItem("Rent", "10", <FileDoneOutlined />, null, "/rent"),
  // getItem("UPI", "11", <FileDoneOutlined />, null, "/admin-values"),
];
const Layouts = ({ children }) => {
  const logout = useStore((state) => state.logout);
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        breakpoint="lg"
        collapsedWidth="0"
      >
        <div
          style={{
            padding: 16,
          }}
        >
          <Image
            src={logo}
            width={80}
            style={{
              borderRadius: 8,
            }}
            preview={false}
          />
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
          selectedKeys={
            items && items.find((item) => item.path === location.pathname)?.key
          }
        >
          {items.map((item) => (
            <Menu.Item
              key={item.key}
              icon={item.icon}
              onClick={() => {
                navigate(item.path);
              }}
            >
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          style={{
            padding: 0,
            background: "#001529",
          }}
        >
          {/* dark nav bar logout*/}

          <div
            style={{
              display: "flex",
              justifyContent: collapsed ? "space-between" : "flex-end",
              alignItems: "center",
              gap: 16,
            }}
          >
            {collapsed && (
              <Image
                src={logo}
                width={80}
                style={{
                  borderRadius: 8,
                  paddingLeft: 16,
                }}
                preview={false}
              />
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 16,
                paddingRight: 16,
              }}
            >
              <a href="" target="_blank" rel="noreferrer">
                <Button type="primary">Google Play</Button>
              </a>
              <a href="" target="_blank" rel="noreferrer">
                <Button type="primary">Analytics</Button>
              </a>
              <Button type="primary" onClick={logout}>
                Logout
              </Button>

              {/* <Button type="primary" onClick={() => navigate("/login")}>
              Logout
            </Button> */}
            </div>
          </div>
        </Header>
        <Content style={{}}>
          {/* <Breadcrumb
            style={{
              margin: "16px 0",
            }}
          >
            <Breadcrumb.Item>User</Breadcrumb.Item>
            <Breadcrumb.Item>Bill</Breadcrumb.Item>
          </Breadcrumb> */}
          <div
            style={{
              minHeight: "90dvh",
              background: colorBgContainer,
              padding: 24,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            {children}
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          Tulsi Admin © 2024
        </Footer>
      </Layout>
    </Layout>
  );
};
export default Layouts;
