import {
  Button,
  Card,
  Image,
  Input,
  message,
  Modal,
  Select,
  Table,
  Upload,
} from "antd";
import React from "react";
import { UploadOutlined } from "@ant-design/icons";
import {
  addPoster,
  addUserNotification,
  deletePoster,
  getAllMovies,
  getAllSeries,
  getPoster,
  updatePoster,
} from "../utils/api";
import useValidate from "../hooks/useValidate";

const Poster = () => {
  const [loading, setLoading] = React.useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [notification, setNotification] = React.useState({});
  const [poster, setPoster] = React.useState({});
  const getPosterHandler = async () => {
    setLoading(true);
    const [success, response] = await getPoster();
    if (success) setPoster(response);
    setLoading(false);
  };
  const addUserNotifications = async () => {
    if (!notification?.banner || !notification?.type || !notification?.id)
      return messageApi.error("Please fill all the fields");
    setLoading(true);
    const formData = new FormData();

    formData.append("banner", notification.banner);
    formData.append("type", notification.type);
    formData.append("id", notification.id);
    const [success, response] = poster?.image
      ? await updatePoster(formData)
      : await addPoster(formData);
    if (success) {
      messageApi.success("Poster added successfully");
      getPosterHandler();
    }
    setLoading(false);
  };
  const [movie, setMovie] = React.useState([]);
  const [series, setSeries] = React.useState([]);

  const getAllMoviesHandler = async () => {
    const [success, data] = await getAllMovies();
    if (success) {
      setMovie(data);
    }
  };

  const getAllSeriesHandler = async () => {
    const [success, data] = await getAllSeries();
    if (success) {
      setSeries(data);
    }
  };

  useValidate();

  React.useEffect(() => {
    getPosterHandler();
    getAllMoviesHandler();
    getAllSeriesHandler();
  }, []);

  return (
    <>
      {contextHolder}
      {!poster?.image && (
        <Card title="Poster" className="notification_card">
          <div className="notification">
            <Select
              placeholder="Select Type"
              options={[
                { label: "Movie", value: "movie" },
                { label: "Series", value: "series" },
              ]}
              onChange={(e) => {
                setNotification({ ...notification, type: e });
              }}
            />

            <Select
              placeholder="Select Movie/Series"
              options={
                notification?.type === "movie"
                  ? movie?.map((item) => {
                      return { label: item.name, value: item._id };
                    })
                  : series?.map((item) => {
                      return { label: item.name, value: item._id };
                    })
              }
              onChange={(e) => {
                setNotification({ ...notification, id: e });
              }}
            />

            <Upload
              onChange={(e) => {
                setNotification({
                  ...notification,
                  banner: e.file.originFileObj,
                });
              }}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </div>
          <Button
            onClick={addUserNotifications}
            loading={loading}
            type="primary"
            style={{ marginTop: "20px" }}
          >
            {poster?.image ? "Update" : "Add"}
          </Button>
        </Card>
      )}
      <Table
        dataSource={poster?.image ? [poster] : []}
        columns={[
          {
            title: "Banner",
            dataIndex: "image",
            key: "image",
            render: (text, record) => (
              <Image
                src={record.image}
                alt="banner"
                style={{ width: "100px", height: "100px" }}
              />
            ),
          },
          {
            title: "Movie/Series Name",
            dataIndex: ["link", "item", "name"],
          },
          {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (text, record) => (
              <>
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    Modal.confirm({
                      title: "Do you want to delete this poster?",
                      onOk: async () => {
                        const [success, response] = await deletePoster();
                        if (success) {
                          messageApi.success("Poster deleted successfully");
                          getPosterHandler();
                        }
                      },
                      onCancel() {},
                    });
                  }}
                >
                  Delete
                </Button>
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default Poster;
