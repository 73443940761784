import {
  ApartmentOutlined,
  BookOutlined,
  ClusterOutlined,
  ContainerOutlined,
  MoneyCollectOutlined,
  PictureOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Statistic, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import useValidate from "../hooks/useValidate";
import { getDashboard } from "../utils/api";

const Dashboard = () => {
  const [data, setData] = React.useState([]);
  const getDashboardData = async () => {
    const [success, response] = await getDashboard();
    if (success) setData(response);
  };

  useValidate();
  React.useEffect(() => {
    getDashboardData();
  }, []);
  return (
    <>
      <Typography.Title level={1} style={{ margin: 0 }}>
        Dashboard
      </Typography.Title>
      {/* dashboard colorfull card for data  diff bg color  */}
      <Row gutter={[16, 16]}>
        {[
          {
            name: "Total Users",
            value: data.users,
            icon: <UserOutlined />,
            link: "/users",
          },
          {
            name: "Total Movies",
            value: data.movies,
            icon: <VideoCameraOutlined />,
            link: "/movies",
          },
          {
            name: "Total Series",
            value: data.series,
            icon: <ClusterOutlined />,
            link: "/series",
          },
          {
            name: "Total Categories",
            value: data.categories,
            icon: <ApartmentOutlined />,
            link: "/categories",
          },
          {
            name: "Total Sub-Categories",
            value: data.subcategories,
            icon: <ApartmentOutlined />,
            link: "/",
          },
          {
            name: "Total Genres",
            value: data.geners,
            icon: <ContainerOutlined />,
            link: "/genres",
          },
          {
            name: "Monthly Transactions",
            value: `Rs ${data.monthlyTransactions}`,
            icon: <MoneyCollectOutlined />,
            link: "/transactions",
          },
          {
            name: "Active Subscriptions",
            value: data.activeSubscriptions,
            icon: <BookOutlined />,
            link: "/transactions",
          },
          {
            name: "Total Banners",
            value: data.banners,
            icon: <PictureOutlined />,
            link: "/banners",
          },
        ]?.map((item, index) => (
          <Col className="gutter-row" span={8} key={index}>
            <Link to={item.link}>
              <Card>
                <Statistic
                  title={item.name}
                  value={item.value}
                  //title color

                  prefix={item.icon}
                />
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Dashboard;
