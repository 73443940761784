import { Button, Input, message, Modal, Table } from "antd";
import React from "react";
import Head from "../components/common/Head";
import useValidate from "../hooks/useValidate";
import {
  addGenre,
  getAllActiveSubs,
  getAllGenres,
  getAllTransaction,
} from "../utils/api";
import { useVT } from "virtualizedtableforantd4";
import { CSVLink } from "react-csv";

const AllSubsActive = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [genres, setGenres] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [vt, set_components] = useVT(() => ({ scroll: { y: 600 } }), []);
  const getAllGenresData = async () => {
    setLoading(true);
    const [success, response] = await getAllActiveSubs();

    if (success) setGenres(response);
    setLoading(false);
  };

  useValidate();
  React.useEffect(() => {
    getAllGenresData();
  }, []);
  return (
    <>
      {contextHolder}
      <Head
        title="Active Subscription"
        customButton={
          <CSVLink
            target="_blank"
            data={
              genres?.map((item) => ({
                name: item?.name,
                email: item?.email,
                phoneNumber: item?.phoneNumber,
                subscriptionType: item?.subscriptionType?.title,
                subscriptionBuyDate: new Date(
                  item?.subscriptionBuyDate
                ).toLocaleDateString(),
                subscriptionExpireDate: new Date(
                  item?.subscriptionExpireDate
                ).toLocaleDateString(),
              })) || []
            }
            filename={`active_subscription${
              new Date().toLocaleDateString() + new Date().toLocaleTimeString()
            }.csv`}
          >
            <Button type="primary">Download CSV</Button>
          </CSVLink>
        }
      />

      <Table
        components={vt}
        loading={loading}
        dataSource={genres}
        scroll={{ x: 1000, y: 600 }}
        pagination={{
          pageSizeOptions: [20, 100, 500, 1000, 5000, 10000, 20000],
        }}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
          },
          {
            title: "Email",
            dataIndex: "email",
          },
          {
            title: "Phone Number",
            dataIndex: "phoneNumber",
          },
          {
            title: "Subscription Name",
            dataIndex: ["subscriptionType", "title"],
          },
          {
            title: "Subscription Buy Date",
            dataIndex: "subscriptionBuyDate",
            render: (text) => {
              return new Date(text).toLocaleDateString();
            },
          },
          {
            title: "Subscription Expire Date",
            dataIndex: "subscriptionExpireDate",
            render: (text) => {
              return new Date(text).toLocaleDateString();
            },
          },
        ]}
      />
    </>
  );
};

export default AllSubsActive;
