import React from "react";

import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

export const isEnable = {
  title: "Enabled",
  dataIndex: "isEnable",
  key: "isEnable",
  render: (text, record) =>
    text ? (
      <CheckCircleTwoTone twoToneColor="#52c41a" />
    ) : (
      <CloseCircleTwoTone twoToneColor="#eb2f96" />
    ),
};

export const isEnable5 = {
  title: "Enable",
  dataIndex: "isDisable",
  key: "isDisable",
  render: (text, record) =>
    text ? (
      <CloseCircleTwoTone twoToneColor="#eb2f96" />
    ) : (
      <CheckCircleTwoTone twoToneColor="#52c41a" />
    ),
};
